import { v4 as uuidv4 } from 'uuid';
import cogoToast from 'cogo-toast';
import axios from 'axios';
import AppConsts from '../../appconst';
import { getLoggedInUserId } from '../../apputils';
import { fetchPageHeaderDetails } from './common-slice';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

export const fetchAllCartList = createAsyncThunk(
  "fetchAllCartList",
  async (_, thunkApi) => {
    try {
      const response = await axios.get(
        `${AppConsts.apiBaseUrl}/Cart/GetAllCartList/userId/${getLoggedInUserId()}`, {
          params:{
            CompanyId: localStorage.getItem('companyId'),
           },
          headers: {
            "Accept":"application/json",
          }
        });
        return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const updateCart = createAsyncThunk(
  "updateCart",
  async (data, thunkApi) => {
    try {
      const response = await axios.put(
        `${AppConsts.apiBaseUrl}/Cart/${data.id}/Quantity/${data.quantity}/Variant/${data.variantId}`, {
          params:{
            CompanyId: localStorage.getItem('companyId'),
            UserId: getLoggedInUserId(),
           },
          headers: {
            "Accept":"application/json",
          },
        });
        thunkApi.dispatch(fetchAllCartList());
        return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const deleteFromCart = createAsyncThunk(
  "deleteFromCart",
  async (id, thunkApi) => {
    try {
      const response = await axios.put(
        `${AppConsts.apiBaseUrl}/Cart/DeleteCart/${id}`, {
          headers: {
            "Accept":"application/json",
          },
        });
        thunkApi.dispatch(fetchPageHeaderDetails());
        return response.data;
        
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const deleteAllFromCart = createAsyncThunk(
  "deleteAllFromCart",
  async (_, thunkApi) => {
    try {
      const response = await axios.put(
        `${AppConsts.apiBaseUrl}/Cart/DeleteAllCart/userId/${getLoggedInUserId()}`, {
          headers: {
            "Accept":"application/json",
          },
        });
        thunkApi.dispatch(fetchPageHeaderDetails());
        return response.data;
        
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);

export const addToCart = createAsyncThunk(
  "addToCart",
  async (data, thunkApi) => {
    try {
      const response = await axios.post(
        `${AppConsts.apiBaseUrl}/Cart/SaveCart`,
        {...data, quantity: data?.quantity && data?.quantity > 1 ? data?.quantity : 1, userId: getLoggedInUserId(),companyId:localStorage.getItem('companyId')}, 
        {
          headers: {
            "Accept":"application/json",
          },
        });
        thunkApi.dispatch(fetchPageHeaderDetails());
        thunkApi.dispatch(fetchAllCartList());
        return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error instanceof Error ? error.message : "Something went wrong");
    }
  }
);
const cartSlice = createSlice({
    name: "cart",
    initialState: {
        cartItems: [],
        cartList :[]
    },
    reducers: {
        // addToCart(state, action) {
        //     const product = action.payload;
        //     if(!product.variation){
        //         const cartItem = state.cartItems.find(item => item.id === product.id);
        //         if(!cartItem){
        //             state.cartItems.push({
        //                 ...product,
        //                 quantity: product.quantity ? product.quantity : 1,
        //                 cartItemId: uuidv4()
        //             });
        //         } else {
        //             state.cartItems = state.cartItems.map(item => {
        //                 if(item.cartItemId === cartItem.cartItemId){
        //                     return {
        //                         ...item,
        //                         quantity: product.quantity ? item.quantity + product.quantity : item.quantity + 1
        //                     }
        //                 }
        //                 return item;
        //             })
        //         }

        //     } else {
        //         const cartItem = state.cartItems.find(
        //             item =>
        //                 item.id === product.id &&
        //                 product.selectedProductColor &&
        //                 product.selectedProductColor === item.selectedProductColor &&
        //                 product.selectedProductSize &&
        //                 product.selectedProductSize === item.selectedProductSize &&
        //                 (product.cartItemId ? product.cartItemId === item.cartItemId : true)
        //         );
        //         if(!cartItem){
        //             state.cartItems.push({
        //                 ...product,
        //                 quantity: product.quantity ? product.quantity : 1,
        //                 cartItemId: uuidv4()
        //             });
        //         } else if (cartItem !== undefined && (cartItem.selectedProductColor !== product.selectedProductColor || cartItem.selectedProductSize !== product.selectedProductSize)) {
        //             state.cartItems = [
        //                 ...state.cartItems,
        //                 {
        //                     ...product,
        //                     quantity: product.quantity ? product.quantity : 1,
        //                     cartItemId: uuidv4()
        //                 }
        //             ]
        //         } else {
        //             state.cartItems = state.cartItems.map(item => {
        //                 if(item.cartItemId === cartItem.cartItemId){
        //                     return {
        //                         ...item,
        //                         quantity: product.quantity ? item.quantity + product.quantity : item.quantity + 1,
        //                         selectedProductColor: product.selectedProductColor,
        //                         selectedProductSize: product.selectedProductSize
        //                     }
        //                 }
        //                 return item;
        //             });
        //         }
        //     }

        //     cogoToast.success("Added To Cart", {position: "bottom-left"});
        // },
        // deleteFromCart(state, action) {
        //     state.cartItems = state.cartItems.filter(item => item.cartItemId !== action.payload);
        //     cogoToast.error("Removed From Cart", {position: "bottom-left"});
        // },
        decreaseQuantity(state, action){
            const product = action.payload;
            if (product.quantity === 1) {
                state.cartItems = state.cartItems.filter(item => item.cartItemId !== product.cartItemId);
                cogoToast.error("Removed From Cart", {position: "bottom-left"});
            } else {
                state.cartItems = state.cartItems.map(item =>
                    item.cartItemId === product.cartItemId
                        ? { ...item, quantity: item.quantity - 1 }
                        : item
                );
                cogoToast.warn("Item Decremented From Cart", {position: "bottom-left"});
            }
        },
        // deleteAllFromCart(state){
        //     state.cartItems = []
        // },
        setCartList(state, action){
            state.cartList = action.payload;
        }
    },
    extraReducers: {
        [fetchAllCartList.fulfilled]: (state, action) => {
            state.loading = false
            state.cartList = action.payload
          },
          [updateCart.fulfilled]: (state, action) => {
            state.loading = false
            cogoToast.success("Successfully update the cart quantity.", {position: "top-center"});
          },
          [deleteFromCart.fulfilled]: (state, action) => {
            state.loading = false
            cogoToast.error("Item Removed From Cart", {position: "top-center"});
          }, 
          [addToCart.fulfilled]: (state, action) => {
            state.loading = false
            cogoToast.success("Added To Cart", {position: "top-center"});
          },
          [deleteAllFromCart.fulfilled]: (state, action) => {
            state.loading = false
            state.cartList = []
          },
      },
});

export const {  decreaseQuantity, setCartList } = cartSlice.actions;
export default cartSlice.reducer;
