import clsx from "clsx";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import FooterCopyright from "../../components/footer/FooterCopyright";
import { socialmedialists } from "../../store/slices/socialmedia-slice";
import { footerSectionList, footersList } from "../../store/slices/footers-slice";

const FooterOne = ({
  backgroundColorClass,
  spaceTopClass,
  spaceBottomClass,
  spaceLeftClass,
  spaceRightClass,
  containerClass,
  extraFooterClass,
  sideMenu
}) => {
  const { companyDetails } = useSelector((state) => state.companies);
  const { socialmedialist } = useSelector((state) => state.socialmedia);
  const { companyLogoImage } = useSelector(state => state.auth);
  const imageUrlLogo = companyLogoImage ? `data:image/png;base64,${companyLogoImage.imageBase64}` : '';
  const { footerLists, footerSectionLists } = useSelector(state => state.footers);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const [getFooterDetails, setFooterDetails] = useState(null);

  useEffect(() => {
    if (companyDetails) {
      setFooterDetails(companyDetails)
    }
  }, [companyDetails]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    if (companyId > 0) {
      dispatch(socialmedialists());
    }
  }, []);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(footersList());
    dispatch(footerSectionList());
  }, []);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    if (companyId > 0) {
      const filter = footerLists.filter(item => item.isVisible === 'T');
      setFilterItems(filter);
    }
  }, [footerLists, localStorage.getItem("companyId")]);

  useEffect(() => {
    const companyId = localStorage.getItem("companyId");
    if (companyId > 0 && footerSectionLists && footerSectionLists.length > 0) {
      const filtered = footerSectionLists.filter(item => item.active === 'T');
      setFilteredItems(filtered);
    }
  }, [footerSectionLists, localStorage.getItem("companyId")]);

  const handleLinkClick = (value) => {
    window.open(value, "_blank");
  };

  const slugify = (text) => {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-')        // Replace spaces with -
      .replace(/[^\w\-]+/g, '')    // Remove all non-word chars
      .replace(/\-\-+/g, '-')      // Replace multiple - with single -
      .replace(/^-+/, '')          // Trim - from start of text
      .replace(/-+$/, '');         // Trim - from end of text
  };


  return (
    <footer className={clsx("footer-area", backgroundColorClass, spaceTopClass, spaceBottomClass, extraFooterClass, spaceLeftClass, spaceRightClass)}>
      <div className={`${containerClass ? containerClass : "container"}`}>
        <div className="row">
          <div
            className={`${sideMenu ? "col-xl-2 col-sm-4" : "col-lg-6 col-sm-4"
              }`}
          >
            {/* footer copyright */}
            {getFooterDetails && <FooterCopyright
              footerLogo="/assets/img/logo/logo.png"
              spaceBottomClass="mb-30"
              footerCompanyList={getFooterDetails}
            />}
          </div>
          {filteredItems?.length > 0 && (
            <div
              className={`${sideMenu ? "col-xl-2 col-sm-4" : "col-lg-2 col-sm-3"
                }`}
            >
              <div className="footer-widget mb-30 ml-30">
                <div className="footer-title">
                  <h3>{t("help_&_support")}</h3>
                </div>
                <div className="footer-list">
                  <ul>
                    {filteredItems.map((footer, index) => (
                      <li key={index}>
                        <Link to={`/pages/${slugify(footer.tittle)}`}>
                          {footer.tittle}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          )}
          {filterItems?.length > 0 && (
            <div
              className={`${sideMenu ? "col-xl-2 col-sm-4" : "col-lg-2 col-sm-3"
                }`}
            >
              <div
                className={`${sideMenu
                  ? "footer-widget mb-30 ml-95"
                  : "footer-widget mb-30 ml-50"
                  }`}
              >
                <div className="footer-title">
                  <h3>{t("useful_links")}</h3>
                </div>
                <div className="footer-list">
                  <ul>
                    {filterItems.map((footer, index) => (
                      <li key={index}>
                        <Link to="#" onClick={() => handleLinkClick(footer.proprietorLink)}>
                          {footer.proprietorName}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          )}
          {socialmedialist?.length > 0 && (
            <div
              className={`${sideMenu ? "col-xl-3 col-sm-4" : "col-lg-2 col-sm-2"
                }`}
            >
              <div
                className={`${sideMenu
                  ? "footer-widget mb-30 ml-145"
                  : "footer-widget mb-30 ml-75"
                  }`}
              >
                <div className="footer-title">
                  <h3>{t("follow_us")}</h3>
                </div>
                <div className="socialmedia-link">
                  <ul>
                    {socialmedialist.map((socialMedia, index) => (
                      <li key={index}>
                        <Link onClick={() => handleLinkClick(socialMedia.socialMediaLink)}>
                          {socialMedia.socialMediaName}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </footer>
  );
};

FooterOne.propTypes = {
  backgroundColorClass: PropTypes.string,
  containerClass: PropTypes.string,
  extraFooterClass: PropTypes.string,
  sideMenu: PropTypes.bool,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  spaceRightClass: PropTypes.string
};

export default FooterOne;
